import baby from './baby1.png';
import boy from './boy.png';
import girl from './girl.png';
import bolao from './bolao.png';
import papais from './papais.png';




export default {
   papais,
   bolao,
   baby,
   boy,
   girl
 };