import { Navbar, Carousel, Main, About, Traits, Papais, Team, Footer } from './containers';

export default function App() {
  return (
    <>
       <Navbar/>
       <Main />
       <About />
       <Papais />
       <Footer />
    </>
  );
}


